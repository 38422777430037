import React from 'react';
import firebase from 'firebase';
import { Route, Redirect } from 'react-router-dom';

import { GenerateLink } from './GenerateLink';
import { Dashboard } from './Dashboard';

interface HomeRouteProps {
  path: string;
}

export const HomeRoute: React.FC<HomeRouteProps> = ({ path }) => {
  const lsUser = JSON.parse(localStorage.getItem('user') as string);
  const SUPER_ADMINS = JSON.parse(localStorage.getItem('admins') as string) || [];
  let user: any = null;
  if (lsUser) {
    user = lsUser;
  } else {
    user = firebase.auth().currentUser;
  }
  return (
    <Route
      path={path}
      render={({ location }) =>
        user ? (
          SUPER_ADMINS.includes(user.email)
            ? <Dashboard/>
            : <GenerateLink user={user}/>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
