import { v1, v4 } from 'uuid';
import CryptoJS from 'crypto-js';
import axios, { AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import { STRINGS } from '../i18n/langs';

const ZROOM_API_URL = 'https://api.xroom.app';
// const SECRET = 'cFPXz7bp2fDgw5eaot9uhi4wnCzVziob';
// const USER = 'okinawa2m.gmail.com';
const SECRET = 'HK6N9hBEb0wq6sd4LvFP6A7XfQpHqwbM';
const USER = 'raimundoela.yahoo.fr';


const { ZRoom } = STRINGS.Services

export interface DataType {
  type: number;
  key: string;
  name: string;
  domain: string;
  email: string;
  uid: string;
  activeRoom: string;
}

export interface LinkStateType {
  setState: (value: string) => void;
  setStateForUsers: (value: string) => void;
  onAction: (text: string, type: string) => void;
  data: DataType | undefined;
  setIsClickable: (value: boolean) => void;
  update: (value: boolean) => void;
  domainsList: DomainsListType[];
}

export interface DomainsListType {
  domain: string;
  rooms: string[];
}

export const getDomainsList = async () => {
  const xRandom = v4();
  const xAuthKey = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(xRandom, SECRET));
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-version': 2,
      'x-random': xRandom,
      'x-auth-id': USER,
      'x-auth-key': xAuthKey,
    },
  };

  const payloadString = JSON.stringify({
    method: 'list',
  });

  const resp = await axios.post(`${ZROOM_API_URL}/api/room`, payloadString, options);
  const domains = get(resp, ['data', 'data'], {})

  return Object.keys(domains);
};

export const getRoomsList = async (domain: string): Promise<any[]> => {
  const xRandom = v4();
  const xAuthKey = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(xRandom, SECRET));
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-version': 2,
      'x-random': xRandom,
      'x-auth-id': USER,
      'x-auth-key': xAuthKey,
    },
  };

  const payloadString = JSON.stringify({
    method: 'list',
    data: {
      domain: domain,
    },
  });

  const resp = await axios.post(`${ZROOM_API_URL}/api/room`, payloadString, options);
  return get(resp, ['data', 'data', domain], {})
};

export const getLinkFromServer = async (data: DataType|undefined) => {
  try {
    const xRandom = v4();
    const xAuthKey = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(xRandom, SECRET));
    let payloadString;
    const roomName = data?.name || xRandom;
    if (data?.key) {
      payloadString = JSON.stringify({
        method: 'init',
        data: {
          id: `${data?.domain}/${roomName || xRandom}`,
          type: data?.type,
          lock: !!data?.key,
          key: data?.key,
          addHostKey: true
        },
      });
    } else {
      payloadString = JSON.stringify({
        method: 'init',
        data: {
          id: `${data?.domain}/${roomName || xRandom}`,
          type: data?.type,
          addHostKey: true
        },
      });
    }

    const options: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-version': 2,
        'x-random': xRandom,
        'x-auth-id': USER,
        'x-auth-key': xAuthKey,
      },
    };

    const resp = await axios.post(`${ZROOM_API_URL}/api/room`, payloadString, options);
    return { id: resp.data.data.id, hostKey: resp.data.data.hostKey };
  } catch (e) {
    console.log('error', e);
    return {};
  }
};

export const destroyRoom = async (roomName: string, setStatus: any, update: any) => {
  const xRandom = v4();
  const xAuthKey = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(xRandom, SECRET));
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-version': 2,
      'x-random': xRandom,
      'x-auth-id': USER,
      'x-auth-key': xAuthKey,
    },
  };

  const payloadString = JSON.stringify({
    method: 'destroy',
    data: {
      id: roomName,
      forceKick: true,
    },
  });

  try {
    const resp = await axios.post(`${ZROOM_API_URL}/api/room`, payloadString, options);
    if (resp.data.data) {
      setStatus(ZRoom.destroyed, 'success');
      update(true);
    } else {
      setStatus(ZRoom.something, 'error');
    }
  } catch (e) {
    console.log(e);
    setStatus(ZRoom.something, 'error');
  }

};
