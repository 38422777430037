import React, { useEffect, useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { Button, Container } from '@material-ui/core';
import './styles.scss';
import axios from 'axios';
import firebase from 'firebase';
import { STRINGS } from '../../../i18n/langs';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../../../components/invar';
import { LangSwitch } from '../../../components/LangSwitch';

interface Row extends AdminRow {
  domains?: string;
  uid?: string;
  adminRoom?: string;
}

interface AdminRow {
  email: string;
}

interface EventTableState {
  columns: Array<Column<Row>>;
  data: Row[];
}

interface AdminTableState {
  columns: Array<Column<Row>>;
  data: AdminRow[];
}

export const Dashboard: React.FC = () => {
  const { Dashboard } = STRINGS.Home;
  const lsUser = JSON.parse(localStorage.getItem('user') as string);
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);
  const [isLoadingAdmin, setIsLoadingAdmin] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [changeLang, setChangeLang] = useState();
  const history = useHistory();
  const [eventState, setEventState] = React.useState<EventTableState>({
    columns: [
      { title: Dashboard.email, field: 'email' },
      { title: Dashboard.domains, field: 'domains' },
      { title: Dashboard.room, field: 'adminRoom' },
    ],
    data: [],
  });
  const [adminState, setAdminState] = React.useState<AdminTableState>({
    columns: [
      { title: Dashboard.email, field: 'email' },
    ],
    data: [],
  });

  const updateUsers = async () => {
    setIsLoadingEvent(true);
    const respUsers = await axios.get(`${API_URL}/users-list`);
    let formattedData: Row[] = [];
    const SUPER_ADMINS = JSON.parse(localStorage.getItem('admins') as string);
    respUsers.data.users.forEach((user: any) => {
      if (!SUPER_ADMINS.includes(user.email)) {
        formattedData.push({
          email: user.email,
          domains: user.domains ? user.domains.split(',').filter((el: any) => !!el).join(', ') : '',
          adminRoom: user.adminRoom,
          uid: user.uid,
        });
      }
    });
    if (respUsers.data.users) {
      setEventState({ ...eventState, ...{ data: formattedData } });
    }
    setIsFirstTime(false);
    setIsLoadingEvent(false);
  };

  const updateAdmins = async () => {
    setIsLoadingAdmin(true);
    const respAdmins = await axios.get(`${API_URL}/admins-list`);
    const respUsers = await axios.get(`${API_URL}/users-list`);
    localStorage.setItem('admins', JSON.stringify(respAdmins.data));
    let formattedData: Row[] = [];
    respAdmins.data.forEach((admin: any) => {
      const uid = respUsers.data.users.find((user: Row) => user.email === admin).uid;
      if (admin !== lsUser.email) {
        formattedData.push({
          email: admin,
          uid,
        });
      }
    });
    if (respAdmins.data) {
      setAdminState({ ...adminState, ...{ data: formattedData } });
    }
    setIsLoadingAdmin(false);
  };

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
      updateUsers();
      updateAdmins();
    }
  }, [isFirstTime]);

  const onAddEvent = (newData: Row) =>
    new Promise((resolve) => {
      setIsLoadingEvent(true);
      setTimeout(() => {
        axios.post(`${API_URL}/add-user`, {
          email: newData.email,
          domains: newData.domains,
          adminRoom: newData.adminRoom,
          emailVerified: true,
        })
          .then(() => {
            return firebase.auth().sendPasswordResetEmail(newData.email);
          })
          .then(() => {
            return updateUsers();
          })
          .then(() => {
            setIsLoadingEvent(false);
            resolve();
          })
          .catch((e) => {
            console.log(e);
            setIsLoadingEvent(false);
            resolve();
          });
      }, 600);
    });

  const onDeleteEvent = (oldData: Row) =>
    new Promise((resolve) => {
      setIsLoadingEvent(true);
      setTimeout(() => {
        axios.post(`${API_URL}/delete-user/${oldData.uid}`)
          .then(() => {
            return updateUsers();
          })
          .then(() => {
            setIsLoadingEvent(false);
            resolve();
          })
          .catch((e) => {
            console.log(e);
            setIsLoadingEvent(false);
            resolve();
          });
      }, 600);
    });

  const onAddAdmin = (newData: Row) =>
    new Promise((resolve) => {
      setIsLoadingAdmin(true);
      setTimeout(async () => {
        const { data } = adminState;
        const newAdminsList: string[] = [];
        data.forEach((admin) => {
          newAdminsList.push(admin.email);
        });
        newAdminsList.push(newData.email);
        newAdminsList.push(lsUser.email);
        try {
          await sendAdminsForUpdate(newAdminsList);
          const respUsers = await axios.get(`${API_URL}/users-list`);
          if (!respUsers.data.users.find((user: any) => user.email === newData.email)) {
            await axios.post(`${API_URL}/add-user`, {
              email: newData.email,
              domains: '',
              emailVerified: true,
            });
            await firebase.auth().sendPasswordResetEmail(newData.email);
          }
          await updateAdmins();
          await updateUsers();
          setIsLoadingAdmin(false);
          resolve();
        } catch (e) {
          console.log(e)
          setIsLoadingAdmin(false);
          resolve();
        }

      }, 600);
    });

  const onDeleteAdmin = (oldData: Row) =>
    new Promise((resolve) => {
      setIsLoadingAdmin(true);
      setTimeout(() => {
        const { data } = adminState;
        const newAdminsList: string[] = [];
        data.forEach((admin) => {
          newAdminsList.push(admin.email);
        });
        newAdminsList.push(lsUser.email);
        const removedIndex = newAdminsList.indexOf(oldData.email);
        newAdminsList.splice(removedIndex, 1);
        sendAdminsForUpdate(newAdminsList)
          .then(() => {
            return updateAdmins();
          })
          .then(() => {
            return updateUsers();
          })
          .then(() => {
            resolve();
            setIsLoadingAdmin(false);
          })
          .catch((e) => {
            console.log(e);
            setIsLoadingAdmin(false);
            resolve();
          });
      }, 600);
    });

  const sendAdminsForUpdate = async (newAdminsList: string[]) => {
    try {
      await axios.put(`${API_URL}/update-admins`, newAdminsList);
    } catch (e) {
      console.log(e);
    }
  };

  const logOut = async () => {
    await firebase.auth().signOut();
    localStorage.clear();
    history.push('/sign-in');
  };

  return (
    <div className={'content-container'}>
      <LangSwitch setState={setChangeLang} state={changeLang}/>
      <Button variant={'outlined'}
              size={'large'}
              color={'default'}
              onClick={logOut}
              className={'log-out-button'}
      >
        {Dashboard.logOut}
      </Button>
      <Container maxWidth={'xl'}>
        <MaterialTable
          title={Dashboard.eventAdmins}
          columns={eventState.columns}
          data={eventState.data}
          options={{
            actionsColumnIndex: -1,
          }}
          isLoading={isLoadingEvent}
          editable={{
            onRowAdd: onAddEvent,
            onRowDelete: onDeleteEvent,
          }}
          actions={[{
            icon: 'edit',
            tooltip: Dashboard.edit,
            onClick: (event, rowData: any) => {
              history.push('/generate-link', {
                uid: rowData.uid,
                email: rowData.email,
                adminRoom: rowData.adminRoom,
                domains: rowData.domains.split(',').filter((el: any) => !!el)
              });
            },
          }]}
          localization={{
            body: {
              emptyDataSourceMessage: Dashboard.no,
              addTooltip: Dashboard.add,
              deleteTooltip: Dashboard.delete,
              editRow: {
                deleteText: Dashboard.deleteText,
                cancelTooltip: Dashboard.cancel,
                saveTooltip: Dashboard.save,
              },
            },
            header: {
              actions: Dashboard.actions,
            },
            pagination: {
              labelDisplayedRows: `{from}-{to} / {count}`,
              labelRowsSelect: Dashboard.rows,
              firstTooltip: Dashboard.first,
              previousTooltip: Dashboard.prev,
              nextTooltip: Dashboard.next,
              lastTooltip: Dashboard.last,
            },
            toolbar: {
              searchTooltip: Dashboard.search,
              searchPlaceholder: Dashboard.search,
            },
          }}
        />
      </Container>
      <Container maxWidth={'xl'} className={'admins-container'}>
        <MaterialTable
          title={Dashboard.appAdmins}
          columns={adminState.columns}
          data={adminState.data}
          options={{
            actionsColumnIndex: -1,
          }}
          isLoading={isLoadingAdmin}
          editable={{
            onRowAdd: onAddAdmin,
            onRowDelete: onDeleteAdmin,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: Dashboard.no,
              addTooltip: Dashboard.add,
              deleteTooltip: Dashboard.delete,
              editRow: {
                deleteText: Dashboard.deleteText,
                cancelTooltip: Dashboard.cancel,
                saveTooltip: Dashboard.save,
              },
            },
            header: {
              actions: Dashboard.actions,
            },
            pagination: {
              labelDisplayedRows: `{from}-{to} / {count}`,
              labelRowsSelect: Dashboard.rows,
              firstTooltip: Dashboard.first,
              previousTooltip: Dashboard.prev,
              nextTooltip: Dashboard.next,
              lastTooltip: Dashboard.last,
            },
            toolbar: {
              searchTooltip: Dashboard.search,
              searchPlaceholder: Dashboard.search,
            },
          }}
        />
      </Container>
    </div>
  );
};
