import React from 'react';
import app from 'firebase/app';

const config = {
  apiKey: "AIzaSyAs3pGrgnQij87g02xhwQClaI07hWJQkEs",
  authDomain: "zroom-ren.firebaseapp.com",
  databaseURL: "https://zroom-ren.firebaseio.com",
  projectId: "zroom-ren",
  storageBucket: "zroom-ren.appspot.com",
  messagingSenderId: "382304116695",
};

export class Firebase {
  constructor() {
    app.initializeApp(config);
  }
}

export const FirebaseContext = React.createContext(null as unknown);
