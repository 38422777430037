import React, { useState } from 'react';
import { createStyles, FormControl, MenuItem, Select, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { STRINGS } from '../i18n/langs';

export const LangSwitch: React.FC<any> = ({ setState, state }) => {
  const [lang, setLang] = useState(STRINGS.getLanguage());
  const classes = useStyles();

  const handleChange = (e: any, setState: (v: boolean) => void, state: boolean) => {
    STRINGS.setLanguage(e.target.value);
    setLang(e.target.value)
    setState(!state);
  }

  return (
    <FormControl variant="outlined" className={classes.formControl} size={'small'}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        onChange={(e) => handleChange(e, setState, state)}
        value={lang}
      >
        <MenuItem value={'en'}>English</MenuItem>
        <MenuItem value={'es'}>Español</MenuItem>
        <MenuItem value={'fr'}>Français</MenuItem>
        {/*<MenuItem value={'ja'}>日本語</MenuItem>*/}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      position: 'absolute',
      top: 15,
      left: 15,
    }
  }),
);
