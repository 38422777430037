import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Button,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { GetLink } from '../../../components/GetLinkComponent';
import { STRINGS } from '../../../i18n/langs';
import { LangSwitch } from '../../../components/LangSwitch';
import './styles.scss';
import { destroyRoom, getRoomsList } from '../../../services/zroom-service';
import axios from 'axios';
import { API_URL } from '../../../components/invar';
import logo from '../../../assets/images/tiblogo.jpeg';

const initialPasswordState = {
  isPassword: false,
  showPassword: false,
  password: '',
};

const getUserData = async (uid: string) => {
  return await axios.get(`${API_URL}/user/${uid}`);
};

const getAllRoomsList = async (domains: string[]) => {
  const roomsInDomains = [];
  for (const domain of domains) {
    const resp = await getRoomsList(domain);
    roomsInDomains.push({
      domain,
      rooms: Object.keys(resp),
      roomsObj: resp
    });
  }

  return roomsInDomains;
};

export const GenerateLinkAdmin: React.FC = () => {
  const { GenerateLinkAdmin } = STRINGS.Home;
  const lsUser = JSON.parse(localStorage.getItem('user') as string);
  const SUPER_ADMINS = JSON.parse(localStorage.getItem('admins') as string) || [];
  const history = useHistory();
  const [user, setUser] = useState(history.location.state as any);
  const [changeLang, setChangeLang] = useState();
  const [isUpdated, setIsUpdated] = useState(true);
  const [domain, setDomain] = useState(user ? user.domains[0] : '');
  const [wasClickedAddDomain, setWasClickedAddDomain] = useState(false);
  const [wasClickedAddAdminRoom, setWasClickedAddAdminRoom] = useState(false);
  const [domainForRemove, setDomainForRemove] = useState(user ? user.domains[0] : '');
  const [newDomain, setNewDomain] = useState('');
  const [status, setStatus] = useState({
    type: '',
    text: '',
  });
  const [domainsList, setDomainsList] = useState([]);
  const [roomType, setRoomType] = useState(1);
  const [passwordState, setPasswordState] = useState(initialPasswordState);
  const [roomName, setRoomName] = useState(user ? user.adminRoom : '');

  const { password, isPassword, showPassword } = passwordState;

  useEffect(() => {
    if (isUpdated && user) {
      getUserData(user.uid)
        .then((resp) => {
          const domains = resp.data.domains.split(',').filter((el: any) => !!el);
          setUser({
            uid: user.uid,
            email: user.email,
            domains,
            adminRoom: resp.data.adminRoom,
            activeRoom: resp.data.activeRoom,
          });
          return getAllRoomsList(domains);
        })
        .then((resp) => {
          setDomainsList(resp as any);
        })
        .catch((e) => {
          console.log('e', e);
        });
      setIsUpdated(false);
    }

    if (status.type === 'success') {
      setTimeout(() => {
        setStatus({
          type: '',
          text: '',
        });
      }, 3000);
    }
  }, [isUpdated]);

  if (!SUPER_ADMINS.includes(lsUser?.email)) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { from: '/generate-link' },
        }}
      />
    );
  }

  const onAction = (text: string, type: string): void => {
    setStatus({ text, type });
  };

  const handleChangeRoomName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomName(e.target.value);
    setStatus({
      type: '',
      text: '',
    });
  };

  const handleChangeNewDomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDomain(e.target.value);
  };

  const showPasswordField = () => {
    setPasswordState({ ...passwordState, ...{ isPassword: !isPassword, password: '' } });
  };

  const handleClickShowPassword = () => {
    setPasswordState({ ...passwordState, ...{ showPassword: !showPassword } });
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({ ...passwordState, ...{ password: e.target.value } });
  };

  const handleChangeDomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value);
  };

  const handleChangeDomainForRemove = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainForRemove(e.target.value);
  };

  const handleRoomTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomType(parseInt(e.target.value));
  };

  const back = () => {
    history.push('/home');
  };

  const addDomain = async () => {
    setWasClickedAddDomain(true);
    const { domains } = user;
    domains.push(newDomain.trim());
    await updateDomains(domains);
    setStatus({
      type: 'success',
      text: GenerateLinkAdmin.domainWas,
    });
    setWasClickedAddDomain(false);
    setNewDomain('');
  };

  const addAdminRoom = async () => {
    setWasClickedAddAdminRoom(true)
    try {
      await axios.put(`${API_URL}/update-admin-room`, {
        uid: user.uid,
        adminRoom: roomName,
      })
    } catch (e) {
      console.log(e)
    }
    setStatus({
      type: 'success',
      text: GenerateLinkAdmin.domainWas,
    });
    setWasClickedAddAdminRoom(false)
  }

  const removeDomain = async () => {
    const { domains } = user;
    const domainIndex = domains.indexOf((domain: string) => domain === domainForRemove);
    domains.splice(domainIndex, 1);
    domainsList.splice(domainIndex, 1);
    await updateDomains(domains);
    setDomainForRemove(domains[0]);
    setDomain(domains[0]);
    setDomainsList(domainsList);
    setStatus({
      type: 'success',
      text: GenerateLinkAdmin.domainRemoved,
    });
  };

  const updateDomains = async (domains: string[]) => {
    try {
      await axios.put(`${API_URL}/update-user-domains`, {
        uid: user.uid,
        domains: domains.join(','),
      });
      setUser({ ...user, ...{ domains } });
      setIsUpdated(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="main-wrapper-2">
      <LangSwitch setState={setChangeLang} state={changeLang}/>
      <Button variant="outlined"
              size="large"
              color="default"
              onClick={back}
              className="back-button"
      >
        {GenerateLinkAdmin.back}
      </Button>
      <Container maxWidth="sm" className="main-container admin-main-container">
        <img className="logo" src={logo} alt="TibVisionLogo"/>
        <Alert severity={status.type !== 'success' ? 'error' : 'success'} className={status.text ? 'block' : 'none'}>
          {status.text}
        </Alert>

        <FormControl component="fieldset" className="fieldset-container">
          <FormLabel component="legend">{GenerateLinkAdmin.room}</FormLabel>
          <RadioGroup row={true} aria-label="room-type" name="room-type" value={roomType}
                      onChange={handleRoomTypeChange}>
            <FormControlLabel value={1}
                              control={<Radio color={'primary'} size={'small'}/>}
                              label={GenerateLinkAdmin.conf}/>
            <FormControlLabel value={2}
                              control={<Radio color={'primary'} size={'small'}/>}
                              label={GenerateLinkAdmin.web}/>
          </RadioGroup>
          <FormHelperText>{GenerateLinkAdmin.pls}</FormHelperText>
          <FormControl className="add-remove-container">
            <TextField
              id={'outlined-textarea'}
              label={GenerateLinkAdmin.name}
              value={roomName}
              onChange={handleChangeRoomName}
              variant={'outlined'}
              size={'small'}
              className="email-field"
              error={status.type === 'name'}
            />
            <Button variant={'outlined'}
                    color={'default'}
                    onClick={addAdminRoom}
                    disabled={wasClickedAddAdminRoom}
            >
              {GenerateLinkAdmin.add}
            </Button>
          </FormControl>
          <FormHelperText>{GenerateLinkAdmin.nameDesc}</FormHelperText>
          <FormControl variant="outlined" className="room-name-container" size={'small'}>
            <InputLabel id="demo-simple-select-outlined-label">{GenerateLinkAdmin.domains}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              //@ts-ignore
              onChange={handleChangeDomain}
              value={domain}
              labelWidth={65}
            >
              {
                user.domains.map((domain: string) => <MenuItem value={domain} key={domain}>{domain}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormHelperText>{GenerateLinkAdmin.plsChoose}</FormHelperText>
          <FormControl className="add-remove-container">
            <TextField
              id={'outlined-textarea'}
              label={GenerateLinkAdmin.newDomain}
              value={newDomain}
              onChange={handleChangeNewDomain}
              variant={'outlined'}
              size={'small'}
              className="email-field"
            />
            <Button variant={'outlined'}
                    color={'default'}
                    onClick={addDomain}
                    disabled={wasClickedAddDomain}
            >
              {GenerateLinkAdmin.add}
            </Button>
          </FormControl>
          <FormHelperText>{GenerateLinkAdmin.here}</FormHelperText>
          <FormControl variant="outlined" className="room-name-container" size={'small'}>
            <InputLabel id="demo-simple-select-outlined-label">{GenerateLinkAdmin.domains}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              //@ts-ignore
              onChange={handleChangeDomainForRemove}
              value={domainForRemove}
              labelWidth={65}
            >
              {
                user.domains.map((domain: string) => <MenuItem value={domain} key={domain}>{domain}</MenuItem>)
              }
            </Select>
            <Button variant={'outlined'}
                    color={'default'}
                    onClick={removeDomain}
            >
              {GenerateLinkAdmin.remove}
            </Button>
          </FormControl>
          <FormHelperText>{GenerateLinkAdmin.hereRemove}</FormHelperText>
          <FormControlLabel
            control={<Switch checked={passwordState.isPassword}
                             color={'primary'}
                             onChange={showPasswordField} name="checkedA"/>}
            label={GenerateLinkAdmin.set}
          />
          <FormHelperText>{GenerateLinkAdmin.toggle}</FormHelperText>
        </FormControl>
        <FormControl variant={'outlined'} className="password-container" size={'small'}>
          <InputLabel htmlFor="outlined-adornment-password">{GenerateLinkAdmin.pass}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            disabled={!isPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={STRINGS.getLanguage() === 'fr' ? 97 : 80}
          />
        </FormControl>

        <div className="rooms-container">
          {
            domainsList.map((d: { domain: string, rooms: string[], roomsObj: any }) => {
              return (
                <div className="inner-top" key={d.domain}>
                  <p className="domain">{GenerateLinkAdmin.domain} <b>{d.domain}</b></p>
                  {
                    d.rooms.filter((r) => user.activeRoom.includes(r)).map((r: string) => {
                      const room = r.split('/')[1];
                      return (
                        <div className="inner" key={r}>
                          <Chip color="primary"
                                className="room"
                                label={`${GenerateLinkAdmin.r} ${room}`}
                                onDelete={() => {
                                  destroyRoom(r, onAction, setIsUpdated);
                                }}/>

                          <p className={'domain link'}>{GenerateLinkAdmin.link} <a target="_blank" href={'https://' + r + '?host-key=' + d.roomsObj[r]['options']['hostKey']}><button>{GenerateLinkAdmin.goToRoom}</button></a> <button onClick={(e) => navigator.clipboard.writeText('https://' + r + '?host-key=' + d.roomsObj[r]['options']['hostKey'])}>{GenerateLinkAdmin.copy}</button></p>
                          <p className={'domain link'}>{GenerateLinkAdmin.linkForUsers} <a target="_blank" href={'https://' + r}><button>{GenerateLinkAdmin.goToRoom}</button></a> <button onClick={(e) => navigator.clipboard.writeText('https://' + r)}>{GenerateLinkAdmin.copy}</button></p>
                        </div>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>

        <GetLink
          data={{
            domain,
            type: roomType,
            name: encodeURIComponent(roomName).toLowerCase(),
            key: '',
            email: lsUser.email,
            uid: user.uid,
            activeRoom: user.activeRoom,
          }}
          domainsList={domainsList}
          update={setIsUpdated}
          onAction={onAction}/>
      </Container>
    </div>
  );
};
