import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route
} from 'react-router-dom';

import { Login } from './pages/Login';
import { HomeRoute } from './pages/Home';
import { ForgotPassword } from './pages/ForgetPassword';
import './App.scss';
import { GenerateLinkAdmin } from './pages/Home/GenerateLinkAdmin';
import { API_URL } from './components/invar';
import axios from 'axios';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';



const App: React.FC = () => {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#7A0711'
            }
        }
    });
  const [isFirstTime, setIsFirstTime] = useState(true);
  const user = JSON.parse(localStorage.getItem('user') as string);
  useEffect(() => {
    if(isFirstTime) {
      axios.get(`${API_URL}/admins-list`)
        .then((resp) => {
          localStorage.setItem('admins', JSON.stringify(resp.data))
          setIsFirstTime(false)
        })
    }
  })

  return (
    <MuiThemeProvider theme={theme}>
    <BrowserRouter>

      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path={'/generate-link'}>
          <GenerateLinkAdmin />
        </Route>
        <HomeRoute path={'/home'} />
        {
          user && user.email
            ? <Redirect
                to={{
                  pathname: "/home",
                  state: { from: '**' }
                }}
              />
            : null
        }
        <Redirect
          to={{
            pathname: "/login",
            state: { from: '**' }
          }}
        />

      </Switch>
    </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
