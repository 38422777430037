import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField } from '@material-ui/core';
import { STRINGS } from '../i18n/langs';
import { DataType, LinkStateType, DomainsListType, getRoomsList, getLinkFromServer } from '../services/zroom-service';
import { API_URL } from './invar';

interface GetLinkProps {
  onAction: (text: string, type: string) => void;
  data: DataType;
  disabled?: boolean;
  update: (value: boolean) => void;
  domainsList: DomainsListType[]
}

const getLink = async ({ setState, setStateForUsers, onAction, data, setIsClickable, update, domainsList }: LinkStateType) => {
  const { GetLink } = STRINGS.components
  setIsClickable(false)
  const SUPER_ADMINS = JSON.parse(localStorage.getItem('admins') as string);
  const checkForOneRoom = domainsList.find((d) => d.domain === data?.domain)
  if (checkForOneRoom?.rooms.length && !SUPER_ADMINS.includes(data?.email as string)) {
    onAction(GetLink.youCan, 'domain')
    setIsClickable(true)
    return;
  }
  try {
    const roomsObj: any = await getRoomsList(data?.domain as string);
    const rooms = Object.keys(roomsObj);
    if (rooms.includes(`${data?.domain}/${data?.name}`)) {
      onAction(GetLink.name, 'name');
    } else {
      const resp: any = await getLinkFromServer(data);
      if (resp.id) {
        setState(`${resp.id}?host-key=${resp.hostKey}`);

        setStateForUsers(resp.id);

        try {
          const newRooms = data?.activeRoom.split(',').filter((el: any) => !!el);
          newRooms?.push(resp.id)
          const userResp = await axios.put(`${API_URL}/update-active-room`, {
            uid: data?.uid,
            activeRoom: newRooms?.join(','),
          })
          localStorage.setItem('user', JSON.stringify({...userResp.data, ...{uid: data?.uid, email: data?.email}}));
        } catch (e) {
          console.log('e', e)
        }
        onAction(GetLink.success,'success' );
        update(true)
      } else {
        onAction(GetLink.error, 'error');
      }
    }
  } catch (e) {
    onAction(GetLink.error, 'error');
  }

  setIsClickable(true)
};

const copyToClipboard = (textField: any) => {
  textField.select();
  document.execCommand('copy');
};

const goToRoom = (link: any) => {
  window.open('https://' + link, '_blank');
};

export const GetLink: React.FC<GetLinkProps> = ({ onAction, data, disabled, update, domainsList }) => {
  const [state, setState] = useState('');
  const [stateForUsers, setStateForUsers] = useState('');
  const [isClickable, setIsClickable] = useState(true)
  let textField: any;
  let textFieldForUsers: any;

  const { GetLink } = STRINGS.components

  const onClick = () => getLink({ setState, setStateForUsers, onAction, data, setIsClickable, update, domainsList });

  return (
    <>
      <Button variant={'outlined'}
              size={'large'}
              color={'default'}
              disabled={disabled || !isClickable}
              onClick={onClick}
      >
        {GetLink.getNew}
      </Button>
    </>
  );
};
