import LocalizedStrings from 'react-localization';

export const STRINGS = new LocalizedStrings({
  en:{
    components: {
      GetLink: {
        youCan: 'You can have only one room per domain. Please chose another domain.',
        getNew: 'Get New Link',
        copy: 'Copy',
        error: 'Something went wrong, please try again later.',
        success: 'Link was created.',
        name: 'This room is already in use. Please choose another room name.',
        goToRoom: 'Go to room'
      },
      LangSwitch: {
        lang: 'Lang'
      }
    },
    ForgetPassword: {
      msg: 'Reset link has been sent to your email, please use it, if you want to reset your password',
      email: 'Email',
      reset: 'Reset',
      signIn: 'Sign In',
    },
    Home: {
      Dashboard: {
        email: 'Email',
        domains: 'Domains',
        room: 'Room',
        eventAdmins: 'Event admins',
        appAdmins: 'App admins',
        edit: 'Edit',
        actions: 'Actions',
        search: 'Search',
        rows: 'rows',
        add: 'Add',
        delete: 'Delete',
        next: 'Next page',
        prev: 'Previous page',
        last: 'Last page',
        first: 'First page',
        no: 'No records to display',
        deleteText: 'Are you sure that you want to delete this row?',
        cancel: 'Cancel',
        save: 'Save',
        logOut: 'LogOut',
      },
      GenerateLink: {
        success: 'Success',
        wrong: 'Something went wrong, please try again later.',
        logOut: 'LogOut',
        room: 'Room Type',
        conf: 'Conference',
        web: 'Webinar',
        pls: 'Please choose one of the options.',
        set: 'Set password',
        toggle: 'Toggle this, if you want to setup a password.',
        pass: 'Password',
        name: 'Room name',
        nameDesc: 'Please enter room name, otherwise it will be generated randomly.',
        domains: 'Domains',
        plsChoose: 'Please choose domain',
        domain: 'Domain:',
        r: 'Room:',
        link: 'Link for admin:',
        linkForUsers: 'Link for users:',
        goToRoom: 'Go to room',
        copy: 'Copy'
      },
      GenerateLinkAdmin: {
        domainWas: 'Domain was added',
        roomWas: 'Admin room was changed',
        domainRemoved: 'Domain was removed',
        success: 'Success',
        wrong: 'Something went wrong, please try again later.',
        logOut: 'LogOut',
        room: 'Room Type',
        conf: 'Conference',
        web: 'Webinar',
        pls: 'Please choose one of the options.',
        set: 'Set password',
        toggle: 'Toggle this, if you want to setup a password.',
        pass: 'Password',
        name: 'Room name',
        nameDesc: 'Please enter room name, otherwise it will be generated randomly.',
        domains: 'Domains',
        plsChoose: 'Please choose domain',
        domain: 'Domain:',
        r: 'Room:',
        link: 'Link:',
        linkForUsers: 'Link for users:',
        back: 'Back',
        newDomain: 'New domain',
        add: 'Add',
        here: 'Here you can add domain to user',
        remove: 'Remove',
        hereRemove: 'Here you can remove domain from user',
        copy: 'Copy',
        goToRoom: 'Go to room'
      }
    },
    Login: {
      pls: 'Please verify your email to continue, link for verification was send to your email.',
      signIn: 'Sign In',
      email: 'Email',
      pass: 'Password',
      forgot: 'Forgot Password',
      registration: 'Registration'
    },
    Services: {
      ZRoom: {
        destroyed: 'Room was successfully destroyed.',
        something: 'Something went wrong, please try again later.',
      }
    }
  },
  es:{
    components: {
      GetLink: {
        youCan: 'Solo puede tener una habitación por dominio. Elija otro dominio.',
        getNew: 'Obtener nuevo enlace',
        copy: 'Copiar',
        error: 'Se produjo un error. Vuelve a intentarlo más tarde.',
        success: 'Se creó el enlace.',
        name: 'Esta habitación ya está en uso. Elija otro nombre de habitación.',
        goToRoom: 'Ir a la habitación'
      },
      LangSwitch: {
        lang: 'Lang'
      }
    },
    ForgetPassword: {
      msg: 'Se ha enviado el enlace de restablecimiento a su correo electrónico, utilícelo si desea restablecer su contraseña',
      email: 'Email',
      reset: 'Reiniciar',
      signIn: 'Registrarse',
    },
    Home: {
      Dashboard: {
        email: 'Email',
        domains: 'Dominios',
        room: 'Habitación',
        eventAdmins: 'Administradores de eventos',
        appAdmins: 'Administradores de aplicaciones',
        edit: 'Editar',
        actions: 'Comportamiento',
        search: 'Buscar',
        rows: 'filas',
        add: 'Añadir',
        delete: 'Eliminar',
        next: 'Siguiente página',
        prev: 'Pagina anterior',
        last: 'Última página',
        first: 'Primera página',
        no: 'No hay registros que mostrar',
        deleteText: '¿Estás segura de que quieres eliminar esta fila?',
        cancel: 'Cancelar',
        save: 'Salvar',
        logOut: 'Cerrar sesión',
      },
      GenerateLink: {
        success: 'Éxito',
        wrong: 'Se produjo un error. Vuelve a intentarlo más tarde.',
        logOut: 'Cerrar sesión',
        room: 'Tipo de habitación',
        conf: 'Conferencia',
        web: 'Webinar',
        pls: 'Elija una de las opciones.',
        set: 'Configurar la clave',
        toggle: 'Alterne esto, si desea configurar una contraseña.',
        pass: 'Contraseña',
        name: 'Nombre de la habitación',
        nameDesc: 'Ingrese el nombre de la habitación; de lo contrario, se generará al azar.',
        domains: 'Dominios',
        plsChoose: 'Por favor elija dominio',
        domain: 'Dominio:',
        r: 'Habitación:',
        link: 'Enlace para administrador:',
        linkForUsers: 'Enlace para usuarias:',
        goToRoom: 'Ir a la habitación',
        copy: 'Copiar'
      },
      GenerateLinkAdmin: {
        domainWas: 'Se agregó el dominio',
        roomWas: 'Se cambió la sala de administración',
        domainRemoved: 'El dominio fue eliminado',
        success: 'Éxito',
        wrong: 'Se produjo un error. Vuelve a intentarlo más tarde.',
        logOut: 'Cerrar sesión',
        room: 'Tipo de habitación',
        conf: 'Conferencia',
        web: 'Webinar',
        pls: 'Elija una de las opciones.',
        set: 'Configurar la clave',
        toggle: 'Alterne esto, si desea configurar una contraseña.',
        pass: 'Contraseña',
        name: 'Nombre de la habitación',
        nameDesc: 'Ingrese el nombre de la habitación; de lo contrario, se generará al azar.',
        domains: 'Dominios',
        plsChoose: 'Por favor elija dominio',
        domain: 'Dominio:',
        r: 'Habitación:',
        link: 'Enlace:',
        linkForUsers: 'Enlace para usuarias:',
        back: 'Espalda',
        newDomain: 'Nuevo dominio',
        add: 'Añadir',
        here: 'Aquí puedes agregar dominio al usuario.',
        remove: 'Eliminar',
        hereRemove: 'Aquí puedes eliminar el dominio del usuario.',
        copy: 'Copiar',
        goToRoom: 'Ir a la habitación'
      }
    },
    Login: {
      pls: 'Verifique su correo electrónico para continuar, se envió el enlace de verificación a su correo electrónico.',
      signIn: 'Registrarse',
      email: 'Email',
      pass: 'Contraseña',
      forgot: 'Se te olvidó tu contraseña',
    },
    Services: {
      ZRoom: {
        destroyed: 'La habitación fue destruida con éxito.',
        something: 'Se produjo un error. Vuelve a intentarlo más tarde.',
      }
    }
  },
  fr:{
    components: {
      GetLink: {
        youCan: 'Vous ne pouvez avoir qu\'une seule salle par domaine. Veuillez choisir un autre domaine.',
        getNew: 'Obtenir un nouveau lien',
        copy: 'Copie',
        error: 'Quelque chose c\'est mal passé. Merci d\'essayer plus tard.',
        success: 'Le lien a été créé.',
        name: 'Cette salle est déjà utilisée. Veuillez choisir un autre nom de chambre.',
        goToRoom: 'Aller dans la chambre'
      },
      LangSwitch: {
        lang: 'Lang'
      }
    },
    ForgetPassword: {
      msg: 'Le lien de réinitialisation a été envoyé à votre adresse e-mail, veuillez l\'utiliser si vous souhaitez réinitialiser votre mot de passe',
      email: 'Email',
      reset: 'Réinitialiser',
      signIn: 'Se connecter',
    },
    Home: {
      Dashboard: {
        email: 'Email',
        domains: 'Domaines',
        room: 'Chambre',
        eventAdmins: 'Administrateurs d\'événements',
        appAdmins: 'Administrateurs d\'applications',
        edit: 'Éditer',
        actions: 'Actions',
        search: 'Chercher',
        rows: 'lignes',
        add: 'Ajouter',
        delete: 'Supprimer',
        next: 'Page suivante',
        prev: 'Page précédente',
        last: 'Dernière page',
        first: 'Première page',
        no: 'Aucun enregistrement à afficher',
        deleteText: 'Êtes-vous sûr de vouloir supprimer cette ligne?',
        cancel: 'Annuler',
        save: 'Sauver',
        logOut: 'Se déconnecter',
      },
      GenerateLink: {
        success: 'Succès',
        wrong: 'Quelque chose c\'est mal passé. Merci d\'essayer plus tard.',
        logOut: 'Se déconnecter',
        room: 'Type de chambre',
        conf: 'Conférence',
        web: 'Séminaire en ligne',
        pls: 'Veuillez choisir l\'une des options.',
        set: 'Définir le mot de passe',
        toggle: 'Activez cette option si vous souhaitez configurer un mot de passe.',
        pass: 'Mot de passe',
        name: 'Nom de la salle',
        nameDesc: 'Veuillez saisir le nom de la salle, sinon il sera généré aléatoirement.',
        domains: 'Domaines',
        plsChoose: 'Veuillez choisir le domaine',
        domain: 'Domaine:',
        r: 'Chambre:',
        link: 'Lien pour l\'administrateur:',
        linkForUsers: 'Lien pour les utilisateurs:',
        goToRoom: 'Aller dans la chambre',
        copy: 'Copie'
      },
      GenerateLinkAdmin: {
        domainWas: 'Le domaine a été ajouté',
        roomWas: 'La salle d\'administration a été modifiée',
        domainRemoved: 'Le domaine a été supprimé',
        success: 'Succès',
        wrong: 'Quelque chose c\'est mal passé. Merci d\'essayer plus tard.',
        logOut: 'Se déconnecter',
        room: 'Type de chambre',
        conf: 'Conférence',
        web: 'Séminaire en ligne',
        pls: 'Veuillez choisir l\'une des options.',
        set: 'Définir le mot de passe',
        toggle: 'Activez cette option si vous souhaitez configurer un mot de passe.',
        pass: 'Mot de passe',
        name: 'Nom de la salle',
        nameDesc: 'Veuillez saisir le nom de la salle, sinon il sera généré aléatoirement.',
        domains: 'Domaines',
        plsChoose: 'Veuillez choisir le domaine',
        domain: 'Domaine:',
        r: 'Chambre:',
        link: 'Lien:',
        linkForUsers: 'Lien pour les utilisateurs:',
        back: 'Arrière',
        newDomain: 'Nouveau domaine',
        add: 'Ajouter',
        here: 'Ici, vous pouvez ajouter un domaine à l\'utilisateur',
        remove: 'Retirer',
        hereRemove: 'Ici, vous pouvez supprimer le domaine de l\'utilisateur',
        copy: 'Copie',
        goToRoom: 'Aller dans la chambre'
      }
    },
    Login: {
      pls: 'Veuillez vérifier votre adresse e-mail pour continuer, le lien de vérification a été envoyé à votre adresse e-mail.',
      signIn: 'Se connecter',
      email: 'Email',
      pass: 'Mot de passe',
      forgot: 'Mot de passe oublié',
    },
    Services: {
      ZRoom: {
        destroyed: 'La pièce a été détruite avec succès.',
        something: 'Quelque chose c\'est mal passé. Merci d\'essayer plus tard.',
      }
    }
  },
  // ja: {
  //   components: {
  //     GetLink: {
  //       getNew: '新しいリンクを取得',
  //       copy: 'コピーする',
  //       error: '何かが間違っています。後でもう一度試してください。',
  //       success: 'リンクが作成されました。',
  //       name: 'この部屋はすでに使用されています。 別の部屋名を選択してください。'
  //     },
  //     LangSwitch: {
  //       lang: '言語'
  //     }
  //   },
  //   ForgetPassword: {
  //     msg: 'リセットリンクがメールに送信されました。パスワードをリセットする場合は、リンクを使用してください',
  //     email: 'Eメール',
  //     reset: 'リセット',
  //     signIn: 'サインイン',
  //     registration: '登録'
  //   },
  //   Home: {
  //     Dashboard: {
  //       email: 'Eメール',
  //       eventAdmins: 'イベント管理者',
  //       actions: '行動',
  //       search: '探す',
  //       rows: '行',
  //       add: '追加',
  //       delete: '削除する',
  //       next: '次のページ',
  //       prev: '前のページ',
  //       last: '最後のページ',
  //       first: '一ページ目',
  //       no: '表示するレコードがありません',
  //       filter: 'フィルタ',
  //       deleteText: 'この行を削除してもよろしいですか？',
  //       cancel: 'キャンセル',
  //       save: '保存する',
  //       logOut: 'ログアウト',
  //     },
  //     GenerateLink: {
  //       success: '成功',
  //       wrong: '何かが間違っています。後でもう一度試してください。',
  //       logOut: 'ログアウト',
  //       room: '部屋タイプ',
  //       conf: '会議',
  //       web: 'ウェブセミナー',
  //       pls: 'いずれかのオプションを選択してください。',
  //       set: 'パスワードを設定してください',
  //       toggle: 'パスワードを設定したい場合は、これを切り替えます。',
  //       pass: 'パスワード',
  //       name: '部屋名',
  //       nameDesc: '部屋名を入力してください。そうしないと、ランダムに生成されます。',
  //     }
  //   },
  //   Login: {
  //     pls: '続行するにはメールアドレスを確認してください',
  //     signIn: 'サインイン',
  //     email: 'Eメール',
  //     pass: 'パスワード',
  //     forgot: 'パスワードをお忘れですか',
  //     registration: '登録'
  //   },
  //   Registration: {
  //     six: 'パスワードには少なくとも6つの記号が必要です。',
  //     notMatch: 'パスワードが一致しません',
  //     verification: 'メールボックスに確認メールを送信します。 リンクをクリックして確認してください。',
  //     registration: '登録',
  //     email: 'Eメール',
  //     pass: 'パスワード',
  //     repeat: 'パスワードを再度入力してください',
  //     signUp: 'サインアップ',
  //     signIn: 'サインイン',
  //     forgot: 'パスワードをお忘れですか',
  //   }
  // }
});
