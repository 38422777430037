import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import firebase from 'firebase';
import {
  Button,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { GetLink } from '../../../components/GetLinkComponent';
import { STRINGS } from '../../../i18n/langs';
import { LangSwitch } from '../../../components/LangSwitch';
import './styles.scss';
import { destroyRoom, getRoomsList } from '../../../services/zroom-service';
import axios from 'axios';
import { API_URL } from '../../../components/invar';
import logo from '../../../assets/images/tiblogo.jpeg';


const initialPasswordState = {
  isPassword: false,
  showPassword: false,
  password: '',
};

const getUserData = async (uid: string) => {
  return await axios.get(`${API_URL}/user/${uid}`)
}

const getAllRoomsList = async (domains: string[]) => {
  const roomsInDomains = [];
  for (const domain of domains) {
    let resp = [];
    try {
      resp = await getRoomsList(domain)
    } catch (e) {
      console.log(e);
    }
    roomsInDomains.push({
      domain,
      rooms: Object.keys(resp),
      roomsObj: resp
    })
  }

  return roomsInDomains;
}

export const GenerateLink: React.FC<any> = ({user}) => {
  const { GenerateLink } = STRINGS.Home;
  const history = useHistory();
  const [changeLang, setChangeLang] = useState();
  const [isUpdated, setIsUpdated] = useState(true)
  const [userData, setUserData] = useState(user);
  const [domain, setDomain] = useState(userData?.domains[0] || '');
  const [status, setStatus] = useState({
    type: '',
    text: '',
  });
  const [domainsList, setDomainsList] = useState([])
  const [roomType, setRoomType] = useState(1);
  const [passwordState, setPasswordState] = useState(initialPasswordState);
  const [roomName, setRoomName] = useState(user?.adminRoom ?? '');

  const { password, isPassword, showPassword } = passwordState;

  const onAction = (text: string, type: string): void => {
    setStatus({text, type});
  };

  const handleChangeRoomName = (e: any) => {
    setRoomName(e.target.value)
    setStatus({
      type: '',
      text: '',
    })
  }

  const showPasswordField = () => {
    setPasswordState({ ...passwordState, ...{ isPassword: !isPassword, password: '' } });
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({ ...passwordState, ...{ password: e.target.value } });
  };

  const handleClickShowPassword = () => {
    setPasswordState({ ...passwordState, ...{ showPassword: !showPassword } });
  };

  const handleRoomTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoomType(parseInt(e.target.value));
  };

  const handleChangeDomain = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(e.target.value)
    setStatus({
      type: '',
      text: '',
    });
  };

  const logOut = () => {
    localStorage.clear();
    firebase.auth().signOut()
    history.push('/login')
  }

  useEffect(() => {
    if (status.type === 'success') {
      setTimeout(() => {
        setStatus({
          type: '',
          text: '',
        });
      }, 3000);
    }
    if (isUpdated) {
      getUserData(user.uid)
        .then((resp) => {
          const domains = resp.data.domains ? resp.data.domains.split(',').filter((el: any) => !!el) : [];
          setUserData({
            uid: user.uid,
            email: user.email,
            adminRoom: user.adminRoom,
            domains,
          })
          return getAllRoomsList(domains)
        })
        .then((resp) => {
          setDomainsList(resp as any);
          setIsUpdated(false)
        })
        .catch((e) => {
          setIsUpdated(false)
        })
    }
  }, [isUpdated, status.type, status.text]);

  return (
    <div className={'main-wrapper'}>
      <LangSwitch setState={setChangeLang} state={changeLang}/>
      <Button variant={'outlined'}
              size={'large'}
              color={'default'}
              onClick={logOut}
              className={'log-out-button'}
      >
        {GenerateLink.logOut}
      </Button>
      <Container maxWidth="sm" className={'main-container'}>
        <img className="logo" src={logo} alt="TibVisionLogo"/>
        <Alert severity={status.type !== 'success' ? 'error' : 'success'} className={status.text ? 'block' : 'none'}>
          {status.text}
        </Alert>

        <FormControl component="fieldset" className={'fieldset-container'}>
          <FormLabel component="legend">{GenerateLink.room}</FormLabel>
          <RadioGroup row={true} aria-label="room-type" name="room-type" value={roomType} onChange={handleRoomTypeChange}>
            <FormControlLabel value={1} control={<Radio color={'primary'} size={'small'}/>} label={GenerateLink.conf} />
            <FormControlLabel value={2} control={<Radio color={'primary'} size={'small'}/>} label={GenerateLink.web} />
          </RadioGroup>
          <FormHelperText>{GenerateLink.pls}</FormHelperText>
          <FormControl className={'room-name-container'}>
            <TextField
              id={'outlined-textarea'}
              label={GenerateLink.name}
              value={roomName}
              onChange={handleChangeRoomName}
              variant={'outlined'}
              size={'small'}
              className={'email-field'}
              error={status.type === 'name'}
              disabled={!!user.adminRoom}
            />
          </FormControl>
          <FormHelperText>{GenerateLink.nameDesc}</FormHelperText>
          <FormControl variant="outlined" className={'room-name-container'} size={'small'}>
            <InputLabel id="demo-simple-select-outlined-label">{GenerateLink.domains}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              //@ts-ignore
              onChange={handleChangeDomain}
              value={domain}
              labelWidth={65}
              error={status.type === 'domain'}
            >
              {
                userData.domains.map((domain: string) => <MenuItem value={domain} key={domain}>{domain}</MenuItem>)
              }
            </Select>
          </FormControl>
          <FormHelperText>{GenerateLink.plsChoose}</FormHelperText>
          <FormControlLabel
            control={<Switch checked={passwordState.isPassword}
                             color={'primary'}
                             onChange={showPasswordField} name="checkedA"/>}
            label={GenerateLink.set}
          />
          <FormHelperText>{GenerateLink.toggle}</FormHelperText>
        </FormControl>
        <FormControl variant={'outlined'} className={'password-container'} size={'small'}>
          <InputLabel htmlFor="outlined-adornment-password">{GenerateLink.pass}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            disabled={!isPassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={STRINGS.getLanguage() === 'fr' ? 97 : 80}
          />
        </FormControl>

        <div className={'rooms-container'}>
          {
            domainsList.map((d: { domain: string, rooms: string[], roomsObj: any }) => {
              return (
                <div className={'inner-top'} key={d.domain}>
                  <p className={'domain'}>{GenerateLink.domain} <b>{d.domain}</b></p>
                  {
                    d.rooms.filter((r) => user.activeRoom.includes(r)).map((r: string) => {
                      const room = r.split('/')[1];
                      return (
                        <div className={'inner'} key={r}>
                          <Chip color="primary"
                                className={'room'}
                                label={`${GenerateLink.r} ${room}`}
                                onDelete={() => {
                                  destroyRoom(r, onAction, setIsUpdated);
                                }}/>

                          <p className={'domain link'}>{GenerateLink.link} <a target="_blank" href={'https://' + r + '?host-key=' + d.roomsObj[r]['options']['hostKey']}><button>{GenerateLink.goToRoom}</button></a> <button onClick={(e) => navigator.clipboard.writeText('https://' + r + '?host-key=' + d.roomsObj[r]['options']['hostKey'])}>{GenerateLink.copy}</button></p>
                          <p className={'domain link'}>{GenerateLink.linkForUsers} <a target="_blank" href={'https://' + r}><button>{GenerateLink.goToRoom}</button></a> <button onClick={(e) => navigator.clipboard.writeText('https://' + r)}>{GenerateLink.copy}</button></p>
                        </div>
                      );
                    })
                  }
                </div>
              )
            })
          }
        </div>

        <GetLink
          data={{
            domain,
            type: roomType,
            key: password,
            name: encodeURIComponent(roomName).toLowerCase(),
            email: user.email,
            uid: user.uid,
            activeRoom: user.activeRoom,
          }}
          update={setIsUpdated}
          domainsList={domainsList}
          onAction={onAction}
          disabled={isPassword && !password}/>
      </Container>
    </div>
  );
};
