import React, { useState } from 'react';
import firebase from 'firebase';
import {
  Button, ButtonGroup, CircularProgress,
  Container,
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import './styles.scss';
import { Link } from 'react-router-dom';
import { STRINGS } from '../../i18n/langs';
import { LangSwitch } from '../../components/LangSwitch';
import logo from '../../assets/images/tiblogo.jpeg';

const initialErrorState = {
  isError: false,
  message: '',
}

export const ForgotPassword: React.FC = () => {
  const [errorState, setErrorState] = useState(initialErrorState);
  const [wasClicked, setWasClicked] = useState(false);
  const [changeLang, setChangeLang] = useState();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const { ForgetPassword } = STRINGS;

  const { isError, message } = errorState;


  const reset = () => {
    setLoading(true)
    setErrorState(initialErrorState)
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        setErrorState({
          ...errorState,
          ...{
            isError: false,
            message: ForgetPassword.msg
          }
        })
        setWasClicked(true)
        setLoading(false)
      })
      .catch((error) => {
        setErrorState({ ...errorState, ...{ isError: true, message: error.message } });
        setLoading(false)
      })
  }

  return (
    <div className={'forgot-main-wrapper'}>
      <LangSwitch setState={setChangeLang} state={changeLang}/>
      <Container maxWidth="sm" className={'main-container'}>
        <img className="logo" src={logo} alt="TibVisionLogo"/>
        <Alert severity={isError ? 'error' : 'success'} className={message ? 'block' : 'none'}>
          {message}
        </Alert>

        <FormControl component="fieldset" className={'fieldset-container'}>
          <FormLabel component="legend">{ForgetPassword.reset}</FormLabel>
        </FormControl>

        <FormControl className={'password-container'}>
          <TextField
            id={'outlined-textarea'}
            label={ForgetPassword.email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant={'outlined'}
            size={'small'}
            className={'email-field'}
          />
        </FormControl>

        <div className={'button-wrapper'}>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading || wasClicked}
            onClick={reset}
          >
            {ForgetPassword.reset}
          </Button>
          {loading && <CircularProgress size={24} className={'buttonProgress'} />}
        </div>

        <ButtonGroup
          size={'small'}
          orientation="vertical"
          color="primary"
          aria-label="small vertical contained primary button group"
          variant="text"
        >
          <Button>
            <Link to={'/sign-in'} className={'link'}>{ForgetPassword.signIn}</Link>
          </Button>
        </ButtonGroup>

      </Container>
    </div>
  );
}
