import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'
import firebase from 'firebase';
import {
  Button, ButtonGroup, CircularProgress,
  Container,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import './styles.scss';
import { Link } from 'react-router-dom';
import { STRINGS } from '../../i18n/langs';
import { LangSwitch } from '../../components/LangSwitch';
import axios from 'axios';
import { API_URL } from '../../components/invar';
import logo from '../../assets/images/tiblogo.jpeg';

const initialPasswordState = {
  showPassword: false,
  password: '',
};

export const Login: React.FC = () => {
  const { Login } = STRINGS
  const [loading, setLoading] = useState(false);
  const [changeLang, setChangeLang] = useState();
  const [errorState, setErrorState] = useState('');
  const [passwordState, setPasswordState] = useState(initialPasswordState);
  const [email, setEmail] = useState('');
  let history = useHistory();

  const {password, showPassword} = passwordState;

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({ ...passwordState, ...{ password: e.target.value } });
  };

  const handleClickShowPassword = () => {
    setPasswordState({ ...passwordState, ...{ showPassword: !showPassword } });
  };

  const signIn = async () => {
    setErrorState('')
    setLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      if (!firebase.auth().currentUser?.emailVerified) {
        await firebase.auth().currentUser?.sendEmailVerification();
        setErrorState(Login.pls);
        setLoading(false)
      } else {
        const user = firebase.auth().currentUser;
        const resp = await axios.get(`${API_URL}/user/${user?.uid}`)
        localStorage.setItem('user', JSON.stringify({
          uid: user?.uid,
          email: user?.email,
          adminRoom: resp.data?.adminRoom ? resp.data?.adminRoom : '',
          activeRoom: resp.data?.activeRoom ? resp.data?.activeRoom : '',
          domains: resp.data?.domains ? resp.data?.domains.split(',').filter((el: any) => !!el) : [],
        }))
        const admins = await axios.get(`${API_URL}/admins-list`)
        localStorage.setItem('admins', JSON.stringify(admins.data));
        history.push("/home");
      }
    } catch (error) {
      setLoading(false)
      setErrorState(error.message);
    }
  }

  const isDisabled = !email || !password;

  return (
    <div className={'login-main-wrapper'}>
      <LangSwitch setState={setChangeLang} state={changeLang}/>
      <Container maxWidth="sm" className={'main-container'}>
        <img className="logo" src={logo} alt="TibVisionLogo"/>
        <Alert severity={'error'} className={errorState ? 'block' : 'none'}>
          {errorState}
        </Alert>

        <FormControl component="fieldset" className={'fieldset-container'}>
          <FormLabel component="legend">{Login.signIn}</FormLabel>
        </FormControl>

        <FormControl className={'password-container'}>
          <TextField
            id={'outlined-textarea'}
            label={Login.email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant={'outlined'}
            size={'small'}
            className={'email-field'}
          />
        </FormControl>

        <FormControl variant={'outlined'} className={'password-container'} size={'small'}>
          <InputLabel htmlFor="outlined-adornment-password">{Login.pass}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={STRINGS.getLanguage() === 'fr' ? 97 : 80}
          />
        </FormControl>

        <div className={'button-wrapper'}>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading || isDisabled}
            onClick={signIn}
          >
            {Login.signIn}
          </Button>
          {loading && <CircularProgress size={24} className={'buttonProgress'} />}
        </div>

        <ButtonGroup
          size={'small'}
          orientation="vertical"
          color="primary"
          aria-label="small vertical contained primary button group"
          variant="text"
        >
          <Button>
            <Link to={'/forgot-password'} className={'link'}>{Login.forgot}</Link>
          </Button>
        </ButtonGroup>

      </Container>
    </div>
  );
}
